/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {x} from '@xstyled/styled-components';
import {Image, Quote, QuoteAuthor} from "smooth-doc/components";
import pluginScreenShot from './plugin-screenshot.png';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    em: "em",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "zenwave-editor---intellij-plugin",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#zenwave-editor---intellij-plugin",
    "aria-label": "zenwave editor   intellij plugin permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ZenWave Editor - IntelliJ Plugin"), "\n", React.createElement(_components.p, null, "ZenWave Editor: Seamless ZDL Editing & SDK Plugin Integration inside IntelliJ Platform. ", React.createElement("span", {
    id: "plugin-install"
  }, " ")), "\n", React.createElement("script", {
    src: "https://plugins.jetbrains.com/assets/scripts/mp-widget.js"
  }), "\n", React.createElement("script", null, "MarketplaceWidget.setupMarketplaceWidget('install', 0, \"#plugin-install\");"), "\n", React.createElement(Image, {
    src: pluginScreenShot,
    alt: "ZenWave Editor IntelliJ Plugin"
  }), "\n", React.createElement("iframe", {
    width: "384px",
    height: "319px",
    src: "https://plugins.jetbrains.com/embeddable/card/22858"
  }), "\n", React.createElement(_components.p, null, "Editing support for the ZDL Domain Model Language:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Running ZenWave SDK plugins right from the editor (requires ", React.createElement(_components.em, null, "cli"), " installed)"), "\n", React.createElement(_components.li, null, "Markdown preview with PlantUML diagrams (requires PlantUML plugin installed)"), "\n", React.createElement(_components.li, null, "Syntax highlighting"), "\n", React.createElement(_components.li, null, "Linting and errors"), "\n"), "\n", React.createElement(_components.p, null, "Work in progress:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Code Completion and Formatting for ZDL syntax"), "\n", React.createElement(_components.li, null, "Code Completion for available SDK plugins and options, including your custom plugins"), "\n", React.createElement(_components.li, null, "Code Navigation (Ctrl+Click) from ZDL model to source code and API definitions"), "\n"), "\n", React.createElement(_components.h1, {
    id: "zenwave-editor-for-vscode",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#zenwave-editor-for-vscode",
    "aria-label": "zenwave editor for vscode permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ZenWave Editor for VSCode"), "\n", React.createElement(_components.p, null, "ZenWave Editor provides Syntax Highlighting for ZDL on VSCode."), "\n", React.createElement(_components.p, null, "It is available on the ", React.createElement(_components.a, {
    href: "https://marketplace.visualstudio.com/items?itemName=ZenWave360.zdl-vscode"
  }, "VSCode Marketplace"), "."), "\n", React.createElement(_components.p, null, "It also works 'on the web', for example on the ", React.createElement(_components.code, null, "github.dev"), " user interface reached by pressing ", React.createElement(_components.code, null, "."), " (the period key) when browsing a repository or Pull Request in GitHub."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
